// Polyfill for ie11
(function () {
    if ( typeof NodeList.prototype.forEach === "function" ) return false;
    NodeList.prototype.forEach = Array.prototype.forEach;
})();

document.addEventListener('DOMContentLoaded', () => {
    const winWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    const $navbar = document.querySelectorAll('.navbar');

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
        // Add a click event on each of them
        $navbarBurgers.forEach( el => {
            el.addEventListener('click', () => {
                // Get the target from the "data-target" attribute
                const target = el.dataset.target;
                const $target = document.getElementById(target);
                // Toggle the "is-active" class on both the "navbar-burger"
                // and the "navbar-menu"
                el.classList.toggle('is-active');
                $target.classList.toggle('is-active');
                if ( window.scrollY === 0 ) {
                    $navbar[0].classList.toggle('active');
                }
            });
        });
    }

    if (winWidth > 1087) {

        // Get all dropdown elements
        const $dropDownParent = document.querySelectorAll('.has-dropdown');

        if ($dropDownParent.length > 0) {
            $dropDownParent.forEach( el => {
                el.addEventListener('mouseenter', ()=> {
                    el.classList.add('is-active');
                });

                el.addEventListener('mouseleave', ()=> {
                    el.classList.remove('is-active');
                });
            });
        }
    }



});
